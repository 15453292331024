// Polyfills
import 'es6-object-assign/auto';
import 'core-js/fn/array/includes';
import 'core-js/fn/promise/finally';
import 'intl'; // For Safari 9

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import analytics, { initialAnalytics } from '../lib/analytics';
import AppStateHOC from '../lib/app-state-hoc.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';

import styles from './index.css';
import RenderGUI from './render-gui.jsx';

initialAnalytics();
// Register "base" page view
analytics.pageview('/');

const AppRoutes = () => (
    <Router>
        <Routes>
            <Route path="/" element={<RenderGUI />} />
            <Route path="/:userId" element={<RenderGUI />} />            
        </Routes>
    </Router>
);

if (supportedBrowser()) {
    ReactDOM.render(<AppRoutes />, document.getElementById('root'));
} else {
    BrowserModalComponent.setAppElement(appTarget);
    const WrappedBrowserModalComponent = AppStateHOC(BrowserModalComponent, true /* localesOnly */);
    const handleBack = () => {};
    ReactDOM.render(<WrappedBrowserModalComponent onBack={handleBack} />, appTarget);
}
